import {
  fetchActiveWeapons,
  fetchVaultedWeapons,
} from "@/game-fortnite/fetches/static.mjs";

function fetchData([tab]) {
  if (tab === "active") {
    return fetchActiveWeapons();
  }
  return fetchVaultedWeapons();
}

export default fetchData;
